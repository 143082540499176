import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../utils';
import RecipeItem from '../../components/RecipeItem';
import { openAdjustQuantityModal } from './mealPlanSlice';
import { useRemoveRecipeFromMealMutation } from '../../store/apiSlice';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from 'src/components/Button';
import { Badge } from 'src/components/Badge';

const MealPlanMeal = ({ meal }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatch = useDispatch();
  const [removeRecipeFromMeal] = useRemoveRecipeFromMealMutation();
  const { date, meal_type, recipes, id: mealId } = meal;
  const isLeftover = meal.primary_meal !== null;

  const handleOpenAdjustQuantityModal = (recipe) => {
    dispatch(openAdjustQuantityModal({
      recipeId: recipe.recipe.recipe_id,
      mealId: mealId,
      initialMultiplier: recipe.multiplier,
    }));
  };

  const handleRemoveRecipeFromMeal = async (recipeId) => {
    try {
      await removeRecipeFromMeal({ meal_id: mealId, recipe_id: recipeId });
    } catch (error) {
      console.error('Failed to remove recipe from meal:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const renderCollapsedView = () => (
    <p className="text-xs break-words">
      {recipes.map(r => r.recipe.recipe_name).join(', ')}
    </p>
  );

  const renderExpandedView = () => (
    <div>
      {isLeftover ? (
        <div className="flex items-center mb-4">
          <Badge variant="destructive">
            Leftovers from {formatDate(meal.primary_meal.date)}'s {meal.primary_meal.meal_type}
          </Badge>
        </div>
      ) : (
        recipes.length === 0 ? (
          <Badge variant="neutral" className="mb-4">No recipes yet</Badge>
        ) : (
          recipes.map((recipeData) => (
            <div className="pb-4" key={recipeData.recipe.recipe_id}>
              <RecipeItem
                recipe={recipeData.recipe}
                quantity={recipeData.multiplier}
                className="pb-2"
                actions={[
                  {
                    actionText: 'Adjust quantity',
                    actionHandler: () => handleOpenAdjustQuantityModal(recipeData)
                  },
                  {
                    actionText: 'Remove from meal',
                    actionHandler: () => handleRemoveRecipeFromMeal(recipeData.recipe.recipe_id)
                  }
                ]}
              />
            </div>
          ))
        )
      )}
    </div>
  );

  return (
    <div className="max-w-full"> {/* Added max-w-full */}
      <div className="flex items-center">
        <h6 className="mr-2 pb-0">
          {formatDate(date)} - {meal_type.charAt(0).toUpperCase() + meal_type.slice(1)}
        </h6>
        {!isLeftover && recipes.length > 0 && (
          <Button onClick={toggleExpand} size="icon" variant="link" className="focus:outline-none">
            {isExpanded ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </Button>
        )}
      </div>
      {isExpanded ? renderExpandedView() : renderCollapsedView()}
    </div>
  );
};

export default MealPlanMeal;