import React from 'react';
import { cn } from "src/lib/utils";

export default function StickyNote({ children, className = '', boxShadowColor = 'black' }) {
    return (
        <div 
            className={cn(
                "inline-flex p-2 border border-white bg-yellow rounded",
                "text-white hover:bg-yellow-100 hover:shadow-offset",
                "hover:-translate-x-boxShadowX hover:-translate-y-boxShadowY",
                className
            )}
            style={{ '--shadow-color': `var(--color-${boxShadowColor})` }}
        >
            {children}
        </div>
    );
}
