import { makeBackendRequest } from '../../api';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { getAuth } from "firebase/auth";
import AuthContext from '../auth/AuthContext';
import { BASE_WS_URL } from '../../constants';
import { MDXEditor } from '@mdxeditor/editor/MDXEditor';
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings';
import { listsPlugin } from '@mdxeditor/editor/plugins/lists';
import { linkPlugin } from '@mdxeditor/editor/plugins/link';
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut';
import { TextArea } from 'src/components/TextArea';




export default function CreateRecipe({generated_recipe_uuid, saveCallback, initialMessage = ''}) {
    const [messages, setMessages] = useState([]);
    const [newFeedback, setNewFeedback] = useState(initialMessage);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const authContext = useContext(AuthContext);
    const currentUser = authContext.currentUser;
    const isEditMode = Boolean(generated_recipe_uuid);
    const ws = useRef(null);
    const mdxEditorRef = useRef(null); // Ref for MDXEditor

    useEffect(() => {
        if (isEditMode) {
            setHasGenerated(true);
            const fetchRecipe = async () => {
                try {
                    const response = await makeBackendRequest(`/recipe/${generated_recipe_uuid}`, 'get');
                    setMessages(response.data.recipe.messages);
                } catch (error) {
                    console.error(error);
                }
            };
    
            fetchRecipe();
        }
    }, [currentUser, isEditMode, generated_recipe_uuid]);

    const handleSubmit = async (event) => {
        mdxEditorRef.current?.setMarkdown(""); // Reset MDXEditor
        event.preventDefault();
    
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        const newMessages = [...messages, { role: 'user', content: newFeedback }];
        setMessages(newMessages);
        setNewFeedback("");
    
        ws.current = new WebSocket(`${BASE_WS_URL}/stream_recipe_generation`);
    
        ws.current.onopen = () => {
            console.log('WebSocket is connected');
            ws.current.send(JSON.stringify({ token: token, messages: newMessages }));
            setHasGenerated(true);
        };
    
        ws.current.onmessage = (event) => {
            setMessages(prevMessages => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                
                // Check if the last message is from the assistant and append the data
                if (lastMessage && lastMessage.role === 'assistant') {
                    return prevMessages.map((msg, index) => 
                        index === prevMessages.length - 1 ? { ...msg, content: msg.content + event.data } : msg
                    );
                } else {
                    // If the last message is not from the assistant, add a new assistant message
                    return [...prevMessages, { role: 'assistant', content: event.data }];
                }
            });
        };
    
        ws.current.onerror = (error) => {
            console.log('WebSocket error:', error);
        };
    
        ws.current.onclose = () => {
            console.log('WebSocket is closed');
        };
    };

    const handleSave = async () => {
        setIsSaving(true);
        const fullRecipe = mdxEditorRef.current?.getMarkdown() || "";
        try {
            const requestData = { messages: messages };
            if (isEditMode) {
                requestData.generated_recipe_uuid = generated_recipe_uuid;
            }
            const response = await makeBackendRequest('/save_recipe', 'post', requestData, true);
            if (response.status === 200) {
                saveCallback(response.data.recipe_id, response.data.generated_recipe_uuid);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        const lastAssistantMessage = messages.slice().reverse().find(msg => msg.role === 'assistant');
        const markdown = lastAssistantMessage ? lastAssistantMessage.content : '';
        mdxEditorRef.current?.setMarkdown(markdown);
    }, [messages]);

    return (
        <div className="w-full">
            <div className="py-2">
                <h1 className="text-4xl font-bold pb-4">Create Recipe</h1>
                <div className="space-y-4 mb-4">
                    {messages.filter(msg => msg.role === 'user').map((msg, index, array) => (
                        <React.Fragment key={index}>
                            <div className="text-gray-500">{msg.content}</div>
                            {index !== array.length - 1 && <hr className="border-t border-gray-300" />}
                        </React.Fragment>
                    ))}
                </div>
                <form onSubmit={handleSubmit} className="flex flex-col items-start justify-between space-y-2">
                    <label className="label">
                        <span className="label-text text-black">Recipe Idea</span>
                    </label>
                    <TextArea placeholder="Enter your idea for a recipe" name="name" value={newFeedback} onChange={(e) => setNewFeedback(e.target.value)} />
                    <button type="submit" className="flex-shrink-0 pb-2">Generate recipe</button>
                </form>
            </div>
            {isSaving && <div className="py-2"><span className="loading loading-dots loading-lg"></span></div>}
            {!isSaving && hasGenerated && (
                <>
                    <hr className="border-t border-green mt-2 p-4" />
                    <div >
                    <MDXEditor 
                        ref={mdxEditorRef} 
                        markdown=" " 
                        onChange={console.log} 
                        contentEditableClassName="mdx-editor-content"
                        plugins={[
                            listsPlugin(), 
                            headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }), 
                            markdownShortcutPlugin(),
                            linkPlugin()
                        ]}
                    />
                    </div>
                    <div className="py-2">
                        <button className="btn bg-green text-black" onClick={handleSave}>Save Recipe</button>
                    </div>
                </>
            )}
        </div>
    );
}





