import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAddExternalRecipeModalOpen: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    openAddExternalRecipeModal: (state) => {
      state.isAddExternalRecipeModalOpen = true;
    },
    closeAddExternalRecipeModal: (state) => {
      state.isAddExternalRecipeModalOpen = false;
    },
  },
});

export const { openAddExternalRecipeModal, closeAddExternalRecipeModal } = globalSlice.actions;

export default globalSlice.reducer;
