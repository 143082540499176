import React, { useEffect, useContext } from 'react';
import MealPlanMealList from './MealPlanMealList';
import MealPlanChat from './MealPlanChat';
import MealIdeaTab from './MealIdeaTab';
import { useParams } from 'react-router-dom';
import { useGetMealPlanQuery, useListMealsQuery } from '../../store/apiSlice';
import { useDispatch } from 'react-redux';
import { setMealPlanId } from './mealPlanSlice';
import NavbarContext from '../../NavBarContext';
import FooterContext from '../../FooterContext';

const MealPlan = () => {
    const { meal_plan_id } = useParams();
    const dispatch = useDispatch();
    const { data: mealPlan, error: mealPlanError, isLoading: mealPlanLoading } = useGetMealPlanQuery(meal_plan_id);
    const { data, error: mealsError, isLoading: mealsLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const mealsData = data?.meals;

    const { setShowNavbar } = useContext(NavbarContext);
    const { setShowFooter } = useContext(FooterContext);

    useEffect(() => {
        if (meal_plan_id) {
            dispatch(setMealPlanId(meal_plan_id));
        }

        // Show navbar, hide footer
        setShowNavbar(true);
        setShowFooter(false);

        // Cleanup function to show footer when component unmounts
        return () => {
            setShowFooter(true);
        };
    }, [meal_plan_id, dispatch, setShowNavbar, setShowFooter]);

    if (mealPlanLoading || mealsLoading) {
        return <div><span className="loading loading-dots loading-lg"></span></div>;
    }

    if (mealPlanError || mealsError) {
        return <div>Error loading meal plan: {mealPlanError?.message || mealsError?.message}</div>;
    }

    return (
        <div className="flex flex-col h-[calc(100vh-64px)]">
            <div className="flex flex-grow overflow-hidden">
                <div className="flex-grow flex flex-col overflow-hidden">
                    <div className="flex-grow overflow-y-auto">
                        <MealIdeaTab />
                    </div>
                    <div className="flex-shrink-0 border-t border-black">
                        <MealPlanChat />
                    </div>
                </div>
                {/* Right column: MealPlanMealList */}
                <div className="flex-shrink-0 w-1/3 overflow-y-auto border-l border-black">
                    <MealPlanMealList />
                </div>
            </div>
        </div>
    );
};

export default MealPlan;
