import React, { useState, useRef } from 'react';
import FocusedTextInput from './FocusedTextInput';
import { testRecipe } from '../testData'; // Import testRecipe as a named export
import NewRecipeItem from './NewRecipeItem';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import ToggleableCard from './ToggleableCard';  // Make sure to import the new component
import ColoredButton from './ColoredButton';
import { Badge } from './Badge';
import { Button } from './Button';
import { Input } from './Input';
import { TextArea } from './TextArea';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from './Dialog';
import { Checkbox } from './Checkbox';
import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator } from './Select';
import { Label } from './Label';
import StickyNote from './StickyNote';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './Tabs';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from './Accordion';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from './DropdownMenu';

const Styling = () => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocusChange = (focused) => {
      setIsFocused(focused);
  };

  const handleCardToggle = (isToggled) => {
    console.log('Card toggled:', isToggled);
    // You can add any additional logic here
  };

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Components</h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Standard Components</h3>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Badges</h3>
            <div className="flex space-x-4">
              <Badge variant="default">Default Badge</Badge>
              <Badge variant="secondary">Secondary Badge</Badge>
              <Badge variant="destructive">Destructive Badge</Badge>
              <Badge variant="outline">Outline Badge</Badge>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Button Variants</h3>
            <div className="flex space-x-4">
              <Button variant="default" size="default">Default Button</Button>
              <Button variant="noShadow" size="default">No Shadow Button</Button>
              <Button variant="dark" size="default">Dark Button</Button>
              <Button variant="link" size="default">Link Button</Button>
              <Button variant="neutral" size="default">Neutral Button</Button>
              <Button variant="reverse" size="default">Reverse Button</Button>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Button Sizes</h3>
            <div className="flex space-x-4">
              <Button variant="default" size="sm">Small Default Button</Button>
              <Button variant="default" size="lg">Large Default Button</Button>
              <Button variant="default" size="icon">TODO: Add Icon</Button>
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Select Variants</h3>
            <div className="flex space-x-4">
              <Select>
                <SelectTrigger className="w-48">
                  <SelectValue placeholder="Default Select" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Produce</SelectLabel>
                    <SelectItem value="fruits">Fruits</SelectItem>
                    <SelectItem value="vegetables">Vegetables</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Butcher</SelectLabel>
                    <SelectItem value="beef">Beef</SelectItem>
                    <SelectItem value="chicken">Chicken</SelectItem>
                    <SelectItem value="pork">Pork</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Frozen Foods</SelectLabel>
                    <SelectItem value="icecream">Ice Cream</SelectItem>
                    <SelectItem value="frozenmeals">Frozen Meals</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Dairy</SelectLabel>
                    <SelectItem value="milk">Milk</SelectItem>
                    <SelectItem value="cheese">Cheese</SelectItem>
                    <SelectItem value="yogurt">Yogurt</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Bakery</SelectLabel>
                    <SelectItem value="bread">Bread</SelectItem>
                    <SelectItem value="pastries">Pastries</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Ethnic</SelectLabel>
                    <SelectItem value="asian">Asian</SelectItem>
                    <SelectItem value="mexican">Mexican</SelectItem>
                    <SelectItem value="indian">Indian</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Other</SelectLabel>
                    <SelectItem value="snacks">Snacks</SelectItem>
                    <SelectItem value="beverages">Beverages</SelectItem>
                    <SelectItem value="cleaning">Cleaning Supplies</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <Select>
                <SelectTrigger className="w-48" variant="neutral">
                  <SelectValue placeholder="Neutral Select" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Produce</SelectLabel>
                    <SelectItem value="fruits">Fruits</SelectItem>
                    <SelectItem value="vegetables">Vegetables</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Dairy</SelectLabel>
                    <SelectItem value="milk">Milk</SelectItem>
                    <SelectItem value="cheese">Cheese</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Checkbox</h3>
            <div className="flex space-x-4">
              <Checkbox />
            </div>
            <div className="flex space-x-4">
              <Label>Label</Label>
              <Checkbox />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Inputs</h3>
            <div className="flex space-x-4">
              <Input type="text" placeholder="Default Input" />
              <Input type="text" placeholder="Disabled Input" disabled />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Focused Text Input</h3>
            <div className="flex space-x-4">
              <FocusedTextInput placeholder="Focused Text Input" onFocusChange={(focused) => console.log('Focused:', focused)} />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Text Area</h3>
            <div className="flex space-x-4">
              <TextArea placeholder="Enter text here..." className="w-full" />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Dialog</h3>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="default">Open Normal Dialog</Button>
              </DialogTrigger>
              <DialogContent variant="default">
                <DialogHeader>
                  <DialogTitle>Normal Dialog Title</DialogTitle>
                  <DialogDescription>
                    This is a description of the normal dialog content.
                  </DialogDescription>
                </DialogHeader>
                <p>This is the main content of the normal dialog. You can add any components or text here.</p>
                <DialogFooter>
                  <Button variant="default">Action</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="default">Open Wide Dialog</Button>
              </DialogTrigger>
              <DialogContent variant="wide">
                <DialogHeader>
                  <DialogTitle>Wide Dialog Title</DialogTitle>
                  <DialogDescription>
                    This is a description of the wide dialog content.
                  </DialogDescription>
                </DialogHeader>
                <p>This is the main content of the wide dialog. You can add any components or text here.</p>
                <DialogFooter>
                  <Button variant="default">Action</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Tabs</h3>
          <Tabs defaultValue="tab1">
            <TabsList>
              <TabsTrigger value="tab1">Tab 1</TabsTrigger>
              <TabsTrigger value="tab2">Tab 2</TabsTrigger>
              <TabsTrigger value="tab3">Tab 3</TabsTrigger>
            </TabsList>
            <TabsContent value="tab1">
              <p>This is the content of Tab 1. You can add any components or text here.</p>
            </TabsContent>
            <TabsContent value="tab2">
              <p>This is the content of Tab 2. You can add any components or text here.</p>
            </TabsContent>
            <TabsContent value="tab3">
              <p>This is the content of Tab 3. You can add any components or text here.</p>
            </TabsContent>
          </Tabs>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Custom Components</h3>
          <div className="mb-4">
            <NewRecipeItem recipe={testRecipe}/>
          </div>
          <div>
            <ToggleableCard handleToggle={handleCardToggle} className="m-4">
              <p>This is a toggleable card. Click me!</p>
            </ToggleableCard>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Sticky Note</h3>
          <StickyNote>
            <p>This is a sticky note with a blue shadow.</p>
          </StickyNote>
        </div>
      </div>
      
      <div>
        <h2 className="text-xl font-bold mb-4">Styling</h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Fonts</h3>
          <h1>Header 1</h1>
          <h2>Header 2</h2>
          <h3>Header 3</h3>
          <h4>Header 4</h4>
          <h5>Header 5</h5>
          <h6>Header 6</h6>
          <p>Paragraph</p>
          <h6>Subtitle</h6>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold mb-2">Colors</h3>
          <div className='bg-gray-800 text-black p-4 m-2'>gray</div>
          <div className='bg-primary text-black p-4 m-2'>primary</div>
          <div className='bg-secondary text-black p-4 m-2'>secondary</div>
          <div className='bg-destructive text-black p-4 m-2'>destructive</div>
          <div className='bg-green text-black p-4 m-2'>green</div>
          <div className='bg-blue text-black p-4 m-2'>blue</div>
          <div className='bg-purple text-black p-4 m-2'>purple</div>
          <div className='bg-pink text-black p-4 m-2'>pink</div>
          <div className='bg-yellow text-black p-4 m-2'>yellow</div>
          <div className='bg-orange text-black p-4 m-2'>orange</div>
        </div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Accordion</h3>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>Is it accessible?</AccordionTrigger>
            <AccordionContent>
              Yes. It adheres to the WAI-ARIA design pattern.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Is it styled?</AccordionTrigger>
            <AccordionContent>
              Yes. It comes with default styles that matches the other components&apos; aesthetic.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>Is it animated?</AccordionTrigger>
            <AccordionContent>
              Yes. It&apos;s animated by default, but you can disable it if you prefer.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Dropdown Menu</h3>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="default">Open Dropdown</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>Profile</DropdownMenuItem>
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Help</DropdownMenuItem>
            <DropdownMenuItem>Log out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Styling;
