import React from 'react';
import { Link } from 'react-router-dom';
import localIcon from '../assets/cooking_icon.png';
import Card from './Card';
import { formatDuration } from '../utils';
import { Button } from './Button';

export default function RecipeItem({ recipe, quantity = null, handleDelete = null, deleteText = 'Remove', className = '', actions = [], suppressProcessing = false}) {
    const faviconUrl = recipe.favicon;
    const displayName = quantity && quantity !== 1 ? `${quantity}x ${recipe.recipe_name}` : recipe.recipe_name;
    const imageUrl = recipe.image;
    const domain = recipe.domain ? recipe.domain.replace(/^www\./, '') : '';
    const domainDescription = recipe.domain_description || domain;

    const recipeDetails = [
        recipe.rating_value ? `Rating: ${Number(recipe.rating_value).toFixed(1)}` : null,
        recipe.rating_count ? `${recipe.rating_count} review${recipe.rating_count === 1 ? '' : 's'}` : null,
        recipe.parsed_ingredients ? `${recipe.parsed_ingredients.length} ingredient${recipe.parsed_ingredients.length === 1 ? '' : 's'}` : null,
        formatDuration(recipe.total_time) ? formatDuration(recipe.total_time) : null,
        recipe.recipe_yield ? `Serves ${recipe.recipe_yield}` : null
    ].filter(Boolean).join(' • ') || recipe.description;

    const finalActions = handleDelete
        ? [...actions, { actionText: deleteText, actionHandler: (id) => handleDelete(id) }]
        : actions;

    const isNativeRecipe = recipe.recipe_type === 'native';
    const linkProps = isNativeRecipe
        ? { as: Link, to: `/recipe/${recipe.recipe_id}` }
        : { as: 'a', href: recipe.url, target: "_blank", rel: "noopener noreferrer" };

    const CardContent = () => (
        <Card className={`w-full ${className} group max-w-[320px]`} boxShadowColor='green'>
            <div className="flex flex-col">
                <div className="flex">
                    {/* Image section */}
                    {imageUrl && (
                        <div className="flex-shrink-0 mr-3">
                            <div className="w-[92px] h-[92px] overflow-hidden">
                                <img 
                                    src={imageUrl} 
                                    alt="Recipe" 
                                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                                    style={{ objectPosition: 'center' }}
                                />
                            </div>
                        </div>
                    )}
                    {/* Text content section */}
                    <div className="flex-grow min-w-0">
                        <div className="text-md font-bold line-clamp-2 text-black">
                            {displayName}
                        </div>
                        <div className="flex items-center mt-1">
                            <img 
                                src={faviconUrl || localIcon} 
                                onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }} 
                                className="w-3 h-3 flex-shrink-0" 
                                alt="Recipe Icon" 
                            />
                            <div className='pl-2 text-xs line-clamp-1 text-black'>{domainDescription}</div>
                        </div>
                        <div className="text-xs text-gray-800 mt-2 line-clamp-3">
                            {recipeDetails}
                        </div>
                    </div>
                </div>
                <div className="flex justify-start mt-1">
                    {finalActions.map((action, index) => (
                        <Button 
                            key={index} 
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); action.actionHandler(recipe.id); }} 
                            className={`text-xs mr-2 ${index !== 0 ? 'bg-gray-300' : ''}`}
                            variant="noShadow"
                            size="sm"
                        >
                            {action.actionText}
                        </Button>
                    ))}
                </div>
            </div>
        </Card>
    );

    return React.createElement(linkProps.as, linkProps, <CardContent />);
}